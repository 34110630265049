@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'simplebar-react/dist/simplebar.min.css';
@import url('fonts.css');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: 'Public Sans', sans-serif;
}
